import {VALIDATION_MSG, GLOBAL_KEYS} from "../constants/constants";
import {lang} from "./checkLanguage";

export const validation = {
    rules: {
        email: function (value) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(value)
        },
        required: function (value, chars = 1) {
            return value.length >= chars;
        },
        number: function (value) {
            const re = /^[0-9]|([0-9]+?(\.)?([0-9]*))/;
            return re.test(value);
        },
        password: function (value) {
            const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}/i;
            return re.test(value);
        },
        repeatPassword: function (value) {
            const passField = document.querySelector('[type="password"]');
            return passField.value === value;
        }
    },
    reCaptcha: false,
    reCaptchaResponse: null,
    validateForm: function (form) {
        const fields = this.findFields(form);
        const btn = form.querySelector('button[type="submit"]');

        this.reCaptcha = form.dataset.recaptcha;

        if (this.reCaptcha) {
            this.prepareReCaptcha(form, btn);
        }

        Array.prototype.forEach.call(fields, field => {
            this.addRulesToField(field);
            this.validateField(field);
            field.addEventListener('change', () => this.validateField(field))
        });
        btn.addEventListener('click', (e) => {
            Array.prototype.forEach.call(form.querySelectorAll('.error'), er => er.remove());
            const result = this.checkRules(fields);
            if (this.reCaptcha && !this.reCaptchaResponse) {
                this.toggleReCaptchaValidationMsg();
                e.preventDefault();
            }
            if (result.indexOf(false) !== -1) {
                e.preventDefault();
            }
        });
    },
    addRulesToField: function (field) {
        const rules = field.dataset.rules.replace(' ', '').split(',');
        field.rules = {};
        field.validated = false;
        rules.forEach(rule => {
            field.rules[rule] = false;
        });
    },
    findFields: function (form) {
        return form.querySelectorAll('[data-rules]');
    },
    checkRules: function (fields) {
        let formValidationResults = [];
        Array.prototype.forEach.call(fields, field => {
            let validationResults = [];

            for (let rule in field.rules) {
                validationResults.push(field.rules[rule]);
            }

            if (validationResults.indexOf(false) === -1) {
                field.validated = true;
            } else {
                field.validated = false;
            }

            if (!field.validated) {
                this.toggleValidationMsg(field);
            } else {
                field.classList.remove('is-not-validated');
            }
            formValidationResults.push(field.validated);
        });

        return formValidationResults;
    },
    validateField: function (field) {
        for (let rule in field.rules) {
            field.rules[rule] = this.rules[rule](field.value);
        }
    },
    toggleValidationMsg: function (field) {
        for (let rule in field.rules) {
            field.classList.add('is-not-validated');
            this.prepareValidationMsg(
                field,
                validationMsg.msg[validationMsg.lang]
                    ? validationMsg.msg[validationMsg.lang][rule]
                    : validationMsg.msg[validationMsg.lang.lang][rule]
            );
        }
    },
    prepareValidationMsg: function (field, msg) {
        const errorBox = document.createElement('p');
        errorBox.innerText = msg;
        errorBox.classList.add('error', 'validation__error');
        this.insertAfter(errorBox, field);
    },
    toggleReCaptchaValidationMsg: function () {
        const reBox = document.getElementById('captcha_container');

        this.prepareValidationMsg(
            reBox,
            validationMsg.msg[validationMsg.lang]
                ? validationMsg.msg[validationMsg.lang].reCaptcha
                : validationMsg.msg[validationMsg.lang.lang].reCaptcha
        );
    },
    insertAfter: function (newNode, referenceNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    },
    prepareReCaptcha: function (form, afterElement) {
        const reCaptchaBox = document.createElement('div');
        reCaptchaBox.setAttribute('id', 'captcha_container');
        reCaptchaBox.classList.add('form__recaptcha');
        form.insertBefore(reCaptchaBox, afterElement);
        setTimeout(() => this.loadCaptcha(this.checkReCaptcha, this), 1000);
    },
    loadCaptcha: function (callback, context) {
        grecaptcha.render('captcha_container', {
            'sitekey': GLOBAL_KEYS.reCaptcha,
            'callback': function (response) {
                callback(response, context);
            }
        });
    },
    checkReCaptcha: function (response, context) {
        context.reCaptchaResponse = response;
    }
};

export const validationMsg = {
    msg: null,
    lang: lang.lang || lang,
    getValidationMsg: function () {
        const request = new XMLHttpRequest();
        request.open('GET', VALIDATION_MSG, false);
        request.send(null);
        if (request.status === 200) {
            this.msg = JSON.parse(request.response);
        }
    }
};
