export function toggleLoginPassword () {
    const link = document.querySelector('.login__link--forget');
    const client = document.querySelector('.login__link--new-client');
    const box = document.querySelector('.login__password');
    const boxClient = document.querySelector('.login__new-client');

    link.addEventListener('click', () => {
        box.classList.add('is-visible');

        if (boxClient.classList.contains('is-visible')){
            box.classList.add('is-visible');
            boxClient.classList.remove('is-visible');
            return;
        }
    });

    client.addEventListener('click', () => {

        boxClient.classList.add('is-visible');

        if (box.classList.contains('is-visible')){
            boxClient.classList.add('is-visible');
            box.classList.remove('is-visible');
            return;
        }
    });

};
