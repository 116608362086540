import {dataBox} from "../functions/data-box";
import {mobileMenu} from "../functions/nav";
import {toggleLoginPassword} from "../functions/login";
import {search} from "../functions/search";
import {truncateText} from "../functions/truncate";
import {share} from "../functions/share";
import {validation, validationMsg} from "../functions/validation";
import {vcard} from "../functions/vCard";
import {cookies} from "../functions/cookies";
import {lang} from "../functions/checkLanguage";
import {tabs} from "../functions/tabs";
import {dateSelect} from "../functions/dateSelect";

export function ComponentsLoader(page) {
    this.page = page;
    this.checkInitializedPage();
}

ComponentsLoader.prototype = {
    checkInitializedPage: function () {
        this.enableDefaultComponents();
        switch (this.page) {
            case 'pol':
                this.enablepolyfilesComponents();
                break;
            case 'home':
                this.enableHomeComponents();
                break;
            case 'offers':
                this.enableOffersComponents();
                break;
            case 'login-page':
                this.enableLoginComponents();
                break;
            case 'comp-profil':
                this.enableCompanyProfilComponents();
                break;
            case 'comp-list':
                this.enableCompanyListComponents();
                break;
            case 'offer':
                this.enableOfferComponents();
                break;
            case 'note-page':
                this.enableNotePageComponents();
                break;
            case 'note-list':
                this.enableNoteListComponents();
                break;
            case 'events-list':
                this.enableEventsListComponents();
                break;
            case 'event':
                this.enableEventPageComponents();
                break;
            case 'report-list':
                this.enableReportListComponents();
                break;
            case 'report-page':
                this.enableReportPageComponents();
                break;
            case 'certificate':
                //generate.pdf();
                break;
            default:
                console.info('only default components')
        }
    },
    enableDefaultComponents: function () {
        lang.checkLanguage();
        mobileMenu.init();
        cookies.isPolicyApproved();

        const forms = document.querySelectorAll('form.validate');

        if (forms.length) {
            validationMsg.getValidationMsg();
            Array.prototype.forEach.call(forms, form => validation.validateForm(form));
        }
    },
    enableHomeComponents: function () {
        search.build();

        const eventsTxt = document.querySelectorAll('.event__txt');
        if (eventsTxt.length) {
            Array.prototype.forEach.call(eventsTxt, el => truncateText(el, 200));
        }

        const notesTxt = document.querySelectorAll('.article-box__txt p');
        if (notesTxt.length) {
            Array.prototype.forEach.call(notesTxt, el => truncateText(el, 200));
        }

        const eventBoxes = document.querySelectorAll('.event');
        const hrBoxes = document.querySelectorAll('.hr-box');
        const offerBoxes = document.querySelectorAll('.offer-box');

        dataBox.changeHeightOfBoxes(eventBoxes, hrBoxes, offerBoxes);
    },
    enableCompanyProfilComponents: function () {
        share.init();
        vcard.init();
    },
    enableOfferComponents: function () {
        share.init();
    },
    enableLoginComponents: function () {
        toggleLoginPassword();
    },
    enableOffersComponents: function () {
        search.build();
    },
    enableCompanyListComponents: function () {
        search.build();
    },
    enableNotePageComponents: function () {
        share.init();
        const eventBoxes = document.querySelectorAll('.event');
        if (eventBoxes.length) {
            window.addEventListener('resize', () => dataBox.setHeightOfBox(eventBoxes));
            dataBox.setHeightOfBox(eventBoxes);
        }
    },
    enableNoteListComponents: function () {
        search.build();
        const eventBoxes = document.querySelectorAll('.event');
        if (eventBoxes.length) {
            window.addEventListener('resize', () => dataBox.setHeightOfBox(eventBoxes));
            dataBox.setHeightOfBox(eventBoxes);
        }
    },
    enableEventPageComponents: function () {
        share.init();
        const eventTxt = document.querySelectorAll('.event__txt');
        if (eventTxt.length) {
            Array.prototype.forEach.call(eventTxt, el => truncateText(el, 200));
        }

        const eventBoxes = document.querySelectorAll('.event');
        if (eventBoxes.length) {
            window.addEventListener('resize', () => dataBox.setHeightOfBox(eventBoxes));
            dataBox.setHeightOfBox(eventBoxes);
        }
    },
    enableEventsListComponents: function () {
        const eventsTabs = Object.create(tabs);

        const companiesEventBoxes = document.querySelectorAll('[data-tab="companies-events"] .event');
        const ahkEventBoxes = document.querySelectorAll('[data-tab="ahk-events"] .event');

        eventsTabs.init(
            'events-tabs',
            true,
            () => dataBox.changeHeightOfBoxes(companiesEventBoxes, ahkEventBoxes)
        );

        search.build();

        const eventTxt = document.querySelectorAll('.event__txt');
        if (eventTxt.length) {
            Array.prototype.forEach.call(eventTxt, el => truncateText(el, 200));
        }

        dataBox.changeHeightOfBoxes(companiesEventBoxes, ahkEventBoxes);

        const ahkSelect = document.querySelector('#ahk-events-date');
        const companiesSelect = document.querySelector('#companies-events-date');

        dateSelect.init(ahkSelect);
        dateSelect.init(companiesSelect);

        document.querySelector('[data-tab="ahk-events"]').classList.remove('is-active');
    },
    enableReportListComponents: function () {
        search.build();
    },
    enableReportPageComponents: function () {
        share.init();
    },
    enablepolyfilesComponents: function () {
        polyfiles();
    }
};
