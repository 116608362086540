export let mobileMenu = {
    header: document.querySelector('body'),
    btn: document.querySelector('#menuToggler'),
    init: function() {
        this.onResize();
        this.changePosition();
        this.closeByBg();

        if (!this.btn) return;

        this.btn.addEventListener('click', () => {
            const isMenuShown = document.querySelector('.is-menu-shown');
            if (isMenuShown) {
                this.hide();
                return;
            }
            this.show();
        });
    },
    show: function () {
        this.header.classList.add('is-menu-shown');
    },
    hide: function () {
        this.header.classList.remove('is-menu-shown');
    },
    changePosition: function () {
        window.addEventListener('scroll', () => {
            const scrollY = window.scrollY;
            if (scrollY > 5) {
                this.header.classList.add('is-scrolled');
                this.btn.style.top = `-${scrollY}px`;
                return;
            }
            this.btn.style.top = '5px';
            this.header.classList.remove('is-scrolled');
        });
    },
    onResize: function () {
        window.addEventListener('resize', () => {
            if (window.innerWidth > 1200) {
                this.header.classList.remove('is-menu-shown');
            }
        });
    },
    closeByBg: function () {
        this.header.addEventListener('click', (e) => {
            if (e.target.classList.contains('is-menu-shown')) {
                this.hide();
            }
        })
    }
};
