export const vcard = {
    begin: 'BEGIN:VCARD\r\nVERSION:3.0\r\nCLASS:PUBLIC\r\n',
    end: 'END:VCARD\r\n',
    fileData: '',
    collectedData: {
    },
    gatherData: function () {
        const datas = document.querySelectorAll('[data-vcard]');

        Array.prototype.forEach.call(datas, data => {
            this.collectedData[data.dataset.vcard] = data.innerText;
        });
    },
    prepareData: function () {
        let i = 0;
        for (let key in this.collectedData) {
            const upperKey = key.toUpperCase();
            const subUpperKey = upperKey.substr(0, 3);

            switch (subUpperKey) {
                case ('FN'):
                    this.fileData += `FN:${this.collectedData['fn']}\r\n`;
                    break;
                case ('N'):
                    this.fileData += `N:${this.collectedData['n']};;;;\r\n`;
                    this.fileData += `ORG:${this.collectedData['n']};;;;\r\n`;
                    break;
                case ('ADR') :
                    !i ? this.fileData += `ADR;TYPE=WORK:;;${this.collectedData['adr-street']};${this.collectedData['adr-city']};${this.collectedData['adr-postal']};${this.collectedData['adr-country']}\r\n` : false;
                    i++;
                    break;
                case ('EMAIL'):
                    this.fileData += `EMAIL;TYPE=WORK:${this.collectedData['email']}\r\n`;
                    break;
                case ('TEL'):
                    this.fileData += `TEL;type=WORK:${this.collectedData['tel']}\r\n`;
                    break;
                case ('FAX'):
                    this.fileData += `TEL;type=FAX:${this.collectedData['fax']}\r\n`;
                    break;
            }
        }
        return this.begin + this.fileData + this.end;
    },
    buildVCard: function () {
        this.gatherData();
        return new Blob([this.prepareData()], {
            type: 'text/vcard;charset=utf-8'
        });
    },
    init: function () {
        const downloadBtn = document.querySelector('.card__btn');

        downloadBtn.addEventListener('click', () => {
            setTimeout(()=> {
                saveAs(
                    this.buildVCard(),
                    `${this.createFileName()}.vcf`,
                    true
                )}, 0);
        });
    },
    createFileName: function () {
        let name = this.collectedData.n.replace(/ /g, '-');
        name = name.replace(/\./g, '');
        return name;
    }
};
