export const share = {
    btns: document.querySelectorAll('.share__item a'),
    params: {
        height: 436,
        width: 626
    },
    url: window.location.href,
    init: function () {
        Array.prototype.forEach.call(this.btns, btn => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                const type = btn.dataset.type;
                const url = btn.getAttribute('href');

                if (url !== '#') {
                   this.url = url;
                }

                this.share(type);
            });
        })
    },
    share: function (type) {
        switch (type) {
            case 'facebook':
                this.shareOnFacebook();
                break;
            case 'twitter':
                this.shareOnTwitter();
                break;
            case 'linkedin':
                this.shareOnLinkedIn();
                break;
            case 'xing':
                this.shareOnXing();
                break;
            default:
                throw new Error('You haven\'t defined type of share url.');
                break;
        }
    },
    shareOnFacebook: function () {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.url)}`,
            'facebook-share-dialog',
            `width=${this.params.width}, height=${this.params.height}`
        );
    },
    shareOnTwitter: function () {
        window.open(
            `https://twitter.com/home?status=${this.url}`,
            'twitter-share-dialog',
            `width=${this.params.width}, height=${this.params.height}`
        )
    },
    shareOnLinkedIn: function () {
        const title = document.querySelector('.headline__page').innerText || document.title;
        window.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}&title=${title}&summary=&source=`,
            'linkedin-share-dialog',
            `width=${this.params.width}, height=${this.params.height}`
        )
    },
    shareOnXing: function () {
        window.open(
            `https://www.xing.com/spi/shares/new?url=${this.url}`,
            'xing-share-dialog',
            `width=${this.params.width}, height=${this.params.height}`
        )
    }
};
