export const dataBox = {
    setHeightOfBox: function (boxes) {
        Array.prototype.forEach.call(boxes, box => box.style.height = null);

        if (window.innerWidth < 768) {
            return;
        }

        let heightsArr = [];
        Array.prototype.forEach.call(boxes, box => heightsArr.push(box.offsetHeight));

        const max = heightsArr.reduce((prev, curr) => prev > curr ? prev : curr);
        Array.prototype.forEach.call(boxes, box => box.style.height = `${max}px`);
    },
    changeHeightOfBoxes: function () {
        const args = arguments.length;
        for (let i = 0; i < args; i++) {
            if (arguments[i].length) {
                window.addEventListener('resize', () => this.setHeightOfBox(arguments[i]));
                this.setHeightOfBox(arguments[i]);
            }
        }
    }
};
