export const tabs = {
    tabs: null,
    currentIndex: 0,
    tabsAmount: 0,
    firstTab: null,
    leftArrow: null,
    rightArrow: null,
    init: function (wrapClass = 'tabs', arrows = true, callback) {
        this.tabs = document.querySelectorAll(`.${wrapClass} .tab`);
        this.tabsAmount = this.tabs.length;
        this.firstTab = this.setFirstTab(wrapClass);
        if (arrows) {
            this.setArrows(wrapClass);
            this.setIndex(wrapClass);
            this.toggleArrows();
        }

        Array.prototype.forEach.call(this.tabs, tab => {
            tab.addEventListener('click', () => this.onClick(tab, wrapClass, arrows, callback))
        });

        if (arrows) this.activateTabsArrows(wrapClass);

        if (callback) callback();
    },
    setFirstTab: function (wrapClass) {
        return document.querySelector(`.${wrapClass} .tab`);
    },
    setArrows: function (wrapClass) {
        this.leftArrow = document.querySelector(`.${wrapClass} .tabs__arrow--left`);
        this.rightArrow = document.querySelector(`.${wrapClass} .tabs__arrow--right`);
    },
    onClick: function (tab, wrapClass, arrows, callback) {
        const tabFor = tab.dataset.tabFor;
        const tabContent = this.findProperTabContent(tabFor);

        const tabs = document.querySelectorAll(`.${wrapClass} .tabs__cnt-single`);
        this.hideAllTabsContent(tabs);
        this.deactivateTabs();
        tab.classList.add('is-active');
        tabContent.classList.add('is-active');

        if (arrows) {
            this.setIndex(wrapClass);
            this.toggleArrows();
        }

        if (callback) {
            callback();
        }
    },
    findProperTabContent: function (attr) {
        return document.querySelector(`[data-tab="${attr}"]`)
    },
    hideAllTabsContent: function (tabs) {
        Array.prototype.forEach.call(tabs, tab => tab.classList.remove('is-active'))
    },
    deactivateTabs: function () {
        Array.prototype.forEach.call(this.tabs, tab => tab.classList.remove('is-active'));
    },
    activateTabsArrows: function (wrapClass) {
        const arrows = document.querySelectorAll(`.${wrapClass} .tabs__arrow`);

        Array.prototype.forEach.call(arrows, arrow => this.onClickTabArrow(arrow))
    },
    onClickTabArrow: function (arrow) {
        arrow.addEventListener('click', () => this.moveTabs(arrow));
    },
    updateCurrentIndex: function (arrow) {
        const dir = arrow.dataset.dir;

        if (dir === 'left') {
            this.currentIndex--;
        }

        if (dir === 'right') {
            this.currentIndex++;
        }
    },
    moveTabs: function (arrow) {
        this.updateCurrentIndex(arrow);
        this.changeTabsPosition();
        this.toggleArrows();
    },
    changeTabsPosition: function () {
        this.firstTab.style.marginLeft = `-${this.currentIndex * 100}%`;
    },
    setIndex: function (wrapClass) {
        const activeTab = document.querySelector(`.${wrapClass} .tab.is-active`);
        this.currentIndex = [].indexOf.call(this.tabs, activeTab);

        this.changeTabsPosition();
    },
    toggleArrows: function () {
        if (this.currentIndex < this.tabsAmount - 1) {
            this.leftArrow.classList.remove('is-hidden');
            this.rightArrow.classList.remove('is-hidden');
        }

        if (this.currentIndex === this.tabsAmount - 1) {
            this.rightArrow.classList.add('is-hidden');
            this.leftArrow.classList.remove('is-hidden');
        }

        if (this.currentIndex === 0) {
            this.leftArrow.classList.add('is-hidden');
            this.rightArrow.classList.remove('is-hidden');
        }
    }
};
