import {PROVINCES_URL} from "../constants/constants";
import {tabs} from "./tabs";

export const search = {
    provinces: null,
    build: function () {
        this.getProvinces();
        this.setSelectedCountries();
        this.initDefaultProvinces();
        this.setSelectedProvinces();
        tabs.init('search-tabs');
    },
    getProvinces: function () {
        const request = new XMLHttpRequest();
        request.open('GET', PROVINCES_URL, false);
        request.send(null);
        if (request.status === 200) {
            this.provinces = JSON.parse(request.response);
            this.prepareCountrySelectors();
        }
    },
    initDefaultProvinces: function () {
        const activeTab = document.querySelector('.tabs__cnt-single.is-active');
        const element = activeTab.querySelector('.change-country');
        if (!element) return;
        const form = this.findClosestElementByClass(element, 'form');
        const provincesSelector = form.querySelector('.provinces');
        const selectedCountry = sessionStorage.getItem(`country-selected-${activeTab.dataset.tab}`) || element.value;

        for (let i = 0; i < element.options.length; i++) {
            if (element.options[i].value === selectedCountry) {
                element.options[i].selected = true;
            }
        }

        if (selectedCountry === 'pl' || selectedCountry === 'de') {
            this.removePrevOptions(provincesSelector);
            this.addNewOptions(selectedCountry, provincesSelector);
        }
    },
    prepareCountrySelectors: function () {
        const countrySelect = document.querySelectorAll('.change-country');
        Array.prototype.forEach.call(countrySelect, selector => {
            selector.addEventListener('change', () => this.onChangeCountry(selector))
        });
        const provincesSelect = document.querySelectorAll('.provinces');
        Array.prototype.forEach.call(provincesSelect, selector => {
            selector.addEventListener('change', () => this.onChangeProvince(selector))
        })
    },
    onChangeCountry: function (element) {
        const form = this.findClosestElementByClass(element, 'form');
        const provincesSelector = form.querySelector('.provinces');
        const selectedCountry = element.value;

        this.storeSelectedCountry(form, selectedCountry);

        provincesSelector.disabled = false;

        this.removePrevOptions(provincesSelector);
        this.addNewOptions(selectedCountry, provincesSelector);

        this.setSelectedProvinces();
    },
    onChangeProvince: function (element) {
        const form = this.findClosestElementByClass(element, 'form');
        const selected = element.value;
        this.storeSelectedProvice(form, selected);
    },
    findClosestElementByClass: function (el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) ;
        return el;
    },
    addNewOptions: function (country, selector) {
        this.provinces[country].provinces.forEach(
            province => {
                const option = document.createElement('option');
                option.innerText = province;
                option.setAttribute('value', province);
                selector.appendChild(option);
            });
    },
    removePrevOptions: function (selector) {
        const currentOptions = selector.querySelectorAll('option');

        if (currentOptions.length > 1) {
            Array.prototype.forEach.call(currentOptions, (item, i) => {
                if (i > 0) {
                    selector.removeChild(item);
                }
            })
        }
    },
    storeSelectedCountry: function (form, selected) {
        const currentTab = this.findClosestElementByClass(form, 'tabs__cnt-single').dataset.tab;
        sessionStorage.setItem(`country-selected-${currentTab}`, selected);
    },
    setSelectedCountries: function () {
        const selects = document.querySelectorAll('.change-country');
        Array.prototype.forEach.call(selects, select => {
            const dataTab = this.findClosestElementByClass(select, 'tabs__cnt-single');
            const form = this.findClosestElementByClass(select, 'form');
            const provincesSelector = form.querySelector('.provinces');

            const selectedCountry = sessionStorage.getItem(`country-selected-${dataTab.dataset.tab}`);
            if (selectedCountry) {
                select.value = selectedCountry;
                provincesSelector.disabled = false;
                this.addNewOptions(select.value, provincesSelector);
            }
        });
    },
    storeSelectedProvice: function (form, selected) {
        const currentTab = this.findClosestElementByClass(form, 'tabs__cnt-single').dataset.tab;
        const country = sessionStorage.getItem(`country-selected-${currentTab}`);
        sessionStorage.setItem(`${country}-province-selected-${currentTab}`, selected);
    },
    setSelectedProvinces: function () {
        const selects = document.querySelectorAll('.provinces');
        Array.prototype.forEach.call(selects, select => {
            const dataTab = this.findClosestElementByClass(select, 'tabs__cnt-single');
            const country = sessionStorage.getItem(`country-selected-${dataTab.dataset.tab}`);
            if (!country) {
                return;
            }
            const selectedProvince = sessionStorage.getItem(`${country}-province-selected-${dataTab.dataset.tab}`);
            if (selectedProvince) {
                select.value = selectedProvince;
            }
        });
    }
};
