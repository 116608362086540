import {lang} from "./checkLanguage";

export const dateSelect = {
    months: {
        pl: {
            first: 'Wybierz:',
            months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień']
        },
        de: {
            first: 'Wählen:',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
        }
    },
    init: function (select, months = 12) {
        const initialValue = this.getInitialValue(select);

        let year = this.getCurrentDate().getFullYear();
        let month = this.getCurrentDate().getMonth();

        this.prepareOption(this.months[lang.lang].first, '0', select);
        for (let i = 0; i < months; i++) {
            const data = `${this.getYear(year)}-${this.getTwoDigitsMonth(month)}`;
            const text = this.prepareText(month, year);
            this.prepareOption(text, data, select);
            if (month === 11) {
                month = 0;
                year++;
            } else {
                month++;
            }
        }

        for (let i = 0; i < select.options.length; i++) {
            if (select.options[i].value === initialValue) {
                select.options[i].selected = true;
            }
        }

        this.setInitialValue(select);
    },
    getCurrentDate: function () {
        return new Date();
    },
    getYear: function (date) {
        return date.toString();
    },
    getTwoDigitsMonth: function (date) {
        const month = (date + 1).toString();
        return month.length === 1 ? 0 + month : month;
    },
    prepareOption: function (text, value, select) {
        const option = document.createElement('option');
        option.setAttribute('value', value);
        option.innerText = text;
        select.appendChild(option);
    },
    prepareText: function (month, year) {
        const monthName = this.getNameOfMonth(month);

        return `${monthName} ${year}`
    },
    getNameOfMonth: function (month) {
        return this.months[lang.lang].months[month]
    },
    setInitialValue: function (select) {
        select.addEventListener('change', () => {
            sessionStorage.setItem(select.getAttribute('id'), select.value);
        })
    },
    getInitialValue: function (select) {
        return sessionStorage.getItem(select.getAttribute('id'));
    }
};
