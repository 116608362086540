import {COOKIES_MSG} from "../constants/constants";
import {lang}  from "./checkLanguage";

export const cookies = {
    cookieData: null,
    lang: lang.lang || lang,
    box: null,
    createPolicyApprovedCookie: function () {
        const expDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        document.cookie = `acceptCookiePolicy=accepted; expires=${expDate}`;
        document.querySelector('body').removeChild(this.box);
    },
    isPolicyApproved: function () {
        const isApproved = this.getCookie('acceptCookiePolicy');
        if (!isApproved) {
            this.getCookiesData();
            this.showCookiesBox();
        }
    },
    getCookie: function (name) {
        const cookieMatch = document.cookie.match(new RegExp(name + '=([^;]+)'));

        if (cookieMatch) return cookieMatch[1];
    },
    getCookiesData: function () {
        const request = new XMLHttpRequest();
        request.open('GET', COOKIES_MSG, false);
        request.send(null);
        if (request.status === 200) {
            this.cookieData = JSON.parse(request.response);
        }
    },
    showCookiesBox: function () {
        const box = document.createElement('div');
        box.classList.add('cookies');

        const ins = document.createElement('div');
        ins.classList.add('cookies__ins');
        ins.innerHTML = this.cookieData[this.lang] ? this.cookieData[this.lang].text : this.cookieData[this.lang.lang].text;

        box.appendChild(ins);

        const btn = document.createElement('button');
        btn.classList.add('btn', 'btn--cookies');
        btn.innerText = this.cookieData[this.lang] ? this.cookieData[this.lang].btnText : this.cookieData[this.lang.lang].btnText;

        ins.appendChild(btn);

        this.box = box;
        document.querySelector('body').appendChild(box);

        btn.addEventListener('click', () => this.createPolicyApprovedCookie());
    }
};
